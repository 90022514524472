export const Metaballs = {
	vert: `
    attribute vec2 aVertexPosition;
    attribute vec2 aTextureCoord;

    uniform mat3 projectionMatrix;

    varying vec2 vTextureCoord;

    void main(void)
    {
        vTextureCoord = aTextureCoord;
        gl_Position = vec4((projectionMatrix * vec3(aVertexPosition, 1.0)).xy, 0.0, 1.0);
    }`,

	frag: `
    #ifdef GL_ES
    precision mediump float;
    #endif

    #define directions vec4(sin(time), cos(time), -sin(time), -cos(time))

    uniform float time;

    uniform vec4 colorA;
    uniform vec4 colorB;
    uniform vec3 iResolution;
    uniform vec2 mouse;
    
    // offset
    uniform vec2 offset_p0;
    uniform vec2 offset_p1;
    uniform vec2 offset_p3;
    uniform vec2 offset_p4;
    uniform vec2 offset_p5;

    // speed
    uniform vec2 speed_p0;
    uniform vec2 speed_p1;
    uniform vec2 speed_p3;
    uniform vec2 speed_p4;
    uniform vec2 speed_p5;

    // size
    uniform float size_p0;
    uniform float size_p1;
    uniform float size_p2;
    uniform float size_p3;
    uniform float size_p4;
    uniform float size_p5;

    // direction
    uniform vec2 direction_p0;
    uniform vec2 direction_p1;
    uniform vec2 direction_p3;
    uniform vec2 direction_p4;
    uniform vec2 direction_p5;

    uniform float antialias;

    varying vec2 vTextureCoord;

    float smoothen(float d1, float d2, float d3, float d4, float d5, float d6) {
      float k = 3.0;
      return -log(exp(-k * d1) + exp(-k * d2) + exp(-k * d3) + exp(-k * d4) + exp(-k * d5) + exp(-k * d6)) / k;
    }

    // directions
    // [0.0, 1.0, 2.0, 3.0]
    float getXYDirection(float dir, float time) {
        if (dir == 0.0) {
            return sin(time);
        } else if (dir == 1.0) {
            return cos(time);
        } else if (dir == 2.0) {
            return -sin(time);
        } else if (dir == 3.0) {
            return -cos(time);
        }
    }

    vec2 getDirection(vec2 direction, float time) {
        float x = getXYDirection(direction.x, time);
        float y = getXYDirection(direction.y, time);
        return vec2(x, y);
    }

    void main() {
        vec2 st = vTextureCoord.xy;
        float ratio = iResolution.x / iResolution.y;
        st.x *= ratio;

        float speed = time / 9.0;

        vec2 dir_p0 = getDirection(direction_p0, speed);
        vec2 dir_p1 = getDirection(direction_p1, speed);
        vec2 dir_p3 = getDirection(direction_p3, speed);
        vec2 dir_p4 = getDirection(direction_p4, speed);
        vec2 dir_p5 = getDirection(direction_p5, speed);
        
        vec2 p0 = vec2((dir_p0.x * speed_p0.x) + offset_p0.x * ratio, dir_p0.y * speed_p0.y + offset_p0.y);
        vec2 p1 = vec2((dir_p1.x * speed_p1.x) + offset_p1.x * ratio, dir_p1.y * speed_p1.y + offset_p1.y);
        vec2 p2 = vec2(mouse.x * ratio, mouse.y);
        vec2 p3 = vec2((dir_p3.x * speed_p3.x) + offset_p3.x * ratio, dir_p3.y * speed_p3.y + offset_p3.y);
        vec2 p4 = vec2((dir_p4.x * speed_p4.x) + offset_p4.x * ratio, dir_p4.y * speed_p4.y + offset_p4.y);
        vec2 p5 = vec2((dir_p5.x * speed_p5.x) + offset_p5.x * ratio, dir_p5.y * speed_p5.y + offset_p5.y);

        float d = smoothen(distance(st, p0) * (size_p0 - ratio), distance(st, p1) * (size_p1 - ratio), distance(st, p2) * (size_p2 - ratio), distance(st, p3) * (size_p3 - ratio), distance(st, p4) * (size_p4 - ratio), distance(st, p5) * (size_p5 - ratio));
        // d *= smoothen(distance(st, p3) * (size_p3 - ratio), distance(st, p4) * (size_p4 - ratio), distance(st, p5) * (size_p5 - ratio));

        vec3 color = vec3(smoothstep(0.8 - antialias, 0.8 + antialias, d)) + mix(colorB.rgb, colorA.rgb, st.y/0.8);

        // vec2 testp = vec2(sin(time) * 0.5 * ratio + 0.5, cos(time) * 0.2 + 0.5);
        // vec2 testp = vec2(sin(time) * 0.5 + 0.5 * ratio, 0.5);
        // float dist = distance(st, testp) * 5.0;
        // vec3 color = vec3(smoothstep(0.8 - antialias, 0.8 + antialias, dist)) + mix(colorB.rgb, colorA.rgb, st.y/0.8);

        gl_FragColor = vec4(color, 1.0);
    }
    `,

	uniforms: {
		time: 0,
		colorA: [1.0, 0.0, 0.0, 1.0],
		colorB: [0.0, 1.0, 0.0, 1.0],
		iResolution: [0.0, 1.0, 0.0],
		mouse: [0.5, 0.5],
		offset_p0: [0.5, 0.5],
		offset_p1: [0.5, 0.5],
		offset_p3: [0.5, 0.8],
		offset_p4: [0.5, 0.7],
		offset_p5: [0.3, 0.7],
		speed_p0: [0.5, 0.2],
		speed_p1: [0.5, 0.0],
		speed_p3: [0.5, 0.2],
		speed_p4: [0.5, 0.0],
		speed_p5: [0.2, 0.3],
		size_p0: 5.0,
		size_p1: 5.0,
		size_p2: 5.0,
		size_p3: 5.0,
		size_p4: 5.0,
		size_p5: 5.0,
		direction_p0: [0.0, 1.0],
		direction_p1: [3.0, 1.0],
		direction_p3: [0.0, 1.0],
		direction_p4: [3.0, 1.0],
		direction_p5: [3.0, 1.0],
		antialias: 0.01,
	},
};
