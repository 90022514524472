import React, { useEffect, useRef, useContext } from "react";
import {
	Application,
	Filter,
	Graphics,
	Sprite,
	SCALE_MODES,
	Ticker,
} from "pixi.js";
import tw from "twin.macro";
import { Metaballs } from "./metaballs";
import { ThemeContext } from "../themes/theme-context";
import useIsMobile from "../hooks/useIsMobile";
import { motion, useAnimation } from "framer-motion";

const StyledBackground = tw.div`fixed top-0 left-0 w-screen h-screen -z-2`;

const Background = () => {
	const ref = useRef(null);

	const { theme, bgPlaying } = useContext(ThemeContext);
	const isMobile = useIsMobile();

	const controls = useAnimation();

	const fadeAnimation = {
		hidden: { opacity: 0 },
		visible: { opacity: 1 },
	};

	const params = {
		offsetX_p0: 0.5,
		offsetY_p0: 0.5,
		offsetX_p1: 0.5,
		offsetY_p1: 0.5,
		offsetX_p3: 0.5,
		offsetY_p3: 0.8,
		offsetX_p4: 0.5,
		offsetY_p4: 0.7,
		offsetX_p5: 0.3,
		offsetY_p5: 0.7,
		speedX_p0: 0.5,
		speedY_p0: 0.2,
		speedX_p1: 0.5,
		speedY_p1: 0.0,
		speedX_p3: 0.5,
		speedY_p3: 0.2,
		speedX_p4: 0.5,
		speedY_p4: 0.0,
		speedX_p5: 0.2,
		speedY_p5: 0.3,
		size_p0: 5.0,
		size_p1: 5.0,
		size_p2: 5.0,
		size_p3: 5.0,
		size_p4: 5.0,
		size_p5: 5.0,
		directionX_p0: 0.0,
		directionY_p0: 1.0,
		directionX_p1: 3.0,
		directionY_p1: 1.0,
		directionX_p3: 0.0,
		directionY_p3: 1.0,
		directionX_p4: 3.0,
		directionY_p4: 1.0,
		directionX_p5: 3.0,
		directionY_p5: 1.0,
		antialias: 0.01,
	};

	// configure shader
	const metaballs = new Filter(null, Metaballs.frag, Metaballs.uniforms);
	// metaballs.uniforms.iResolution = [resolution[0], resolution[1], 1.0];
	metaballs.uniforms.colorA = theme.bgColorA;
	metaballs.uniforms.colorB = theme.bgColorB;
	metaballs.uniforms.offset_p0 = [params.offsetX_p0, params.offsetY_p0];
	metaballs.uniforms.offset_p1 = [params.offsetX_p1, params.offsetY_p1];
	metaballs.uniforms.offset_p3 = [params.offsetX_p3, params.offsetY_p3];
	metaballs.uniforms.offset_p4 = [params.offsetX_p4, params.offsetY_p4];
	metaballs.uniforms.offset_p5 = [params.offsetX_p5, params.offsetY_p5];
	metaballs.uniforms.speed_p0 = [params.speedX_p0, params.speedY_p0];
	metaballs.uniforms.speed_p1 = [params.speedX_p1, params.speedY_p1];
	metaballs.uniforms.speed_p3 = [params.speedX_p3, params.speedY_p3];
	metaballs.uniforms.speed_p4 = [params.speedX_p4, params.speedY_p4];
	metaballs.uniforms.speed_p5 = [params.speedX_p5, params.speedY_p5];
	metaballs.uniforms.size_p0 = params.size_p0;
	metaballs.uniforms.size_p1 = params.size_p1;
	metaballs.uniforms.size_p2 = params.size_p2;
	metaballs.uniforms.size_p3 = params.size_p3;
	metaballs.uniforms.size_p4 = params.size_p4;
	metaballs.uniforms.size_p5 = params.size_p5;
	metaballs.uniforms.direction_p0 = [
		params.directionX_p0,
		params.directionY_p0,
	];
	metaballs.uniforms.direction_p1 = [
		params.directionX_p1,
		params.directionY_p1,
	];
	metaballs.uniforms.direction_p3 = [
		params.directionX_p3,
		params.directionY_p3,
	];
	metaballs.uniforms.direction_p4 = [
		params.directionX_p4,
		params.directionY_p4,
	];
	metaballs.uniforms.direction_p5 = [
		params.directionX_p5,
		params.directionY_p5,
	];
	metaballs.uniforms.antialias = 0.01;

	const app = new Application({ backgroundAlpha: 0 });

	let sprite = new Sprite();
	let rect = new Graphics();

	let ticker = Ticker.shared;
	ticker.autoStart = false;

	useEffect(() => {
		if (ref.current.children.length > 0) {
			ref.current.removeChild(ref.current.children[0]);
		}

		ref.current.appendChild(app.view);

		let resolution = [window.innerWidth, window.innerHeight];
		const w = resolution[0];
		const h = resolution[1];

		metaballs.uniforms.iResolution = [resolution[0], resolution[1], 1.0];

		// draw rectangle
		rect.beginFill(0x000000, 0.0);
		rect.drawRect(0, 0, w, h);
		const tex = app.renderer.generateTexture(
			rect,
			SCALE_MODES.NEAREST,
			1,
			rect.getBounds()
		);

		// draw sprite with extracted texture
		sprite.texture = tex;
		sprite.filters = [metaballs];
		app.stage.addChild(sprite);

		const setMousePosition = (e) => {
			let pos = [
				e.data.global.x / resolution[0],
				e.data.global.y / resolution[1],
			];
			metaballs.uniforms.mouse = pos;
		};

		// handle mouse
		if (!isMobile) {
			if (bgPlaying) {
				app.stage.interactive = true;
				app.stage.on("pointermove", (e) => setMousePosition(e));
				app.stage.on("pointerdown", (e) => setMousePosition(e));
			}
		}

		let time = 0;
		ticker.add((delta) => {
			time += 1 / 60;
			metaballs.uniforms.time = time;
		});

		if (bgPlaying) {
			ticker.start();
		} else {
			ticker.stop();
		}

		// update shader on window resize
		const handleResize = () => {
			app.renderer.resize(window.innerWidth, window.innerHeight);
			sprite.width = app.renderer.width;
			sprite.height = app.renderer.height;

			resolution[0] = window.innerWidth;
			resolution[1] = window.innerHeight;

			metaballs.uniforms.iResolution = [resolution[0], resolution[1], 1.0];
		};

		window.addEventListener("resize", handleResize);

		// resize
		handleResize();

		controls
			.start({ opacity: 0, transition: { duration: 0 } })
			.then(() => controls.start("visible"));

		return () => {
			controls
				.start({ opacity: 1, transition: { duration: 0 } })
				.then(() => controls.start("hidden"));

			window.removeEventListener("resize", handleResize);

			app.stage.interactive = false;
			app.stage.off("pointermove", (e) => setMousePosition(e));
			app.stage.off("pointerdown", (e) => setMousePosition(e));

			app.destroy(true, true);
		};
	});

	return (
		<motion.div
			initial="hidden"
			animate={controls}
			variants={fadeAnimation}
			transition={{ duration: 0.8 }}
		>
			<StyledBackground ref={ref} />
		</motion.div>
	);
};

export default Background;
